.d-table-awesome-component {
    font-size: 12px;
    line-height: 16px;
    th,
    td {
        font-size: 12px;
        line-height: 16px;
        border-left: 0!important;
        border-right: 0!important;
    }
    .ant-table-thead {
        & > tr {
            & > th {
                padding: 8px 16px;
                background-color: #a7a7a71a!important;
            }
        }
    }
    .ant-table-tbody {
        & > tr {
            & > td {
                padding: 8px 16px;
            }
        }
    }
    .d-table-awesome-component__select-column {
      margin: 0!important;
      button {
        font-size: 12px!important;
      }
    } 
}
