// .app-layout__wrapper {
//     height: 100vh;
//     overflow-y: hidden;
// }
// @import "./variables";

$compact-menu-width: 50px;
$expand-menu-width: 300px;

$compact-menu-nav-header-height: 45px;

$compact-main-width: calc(100vw - 50px);
$expand-main-width: calc(100vw - 300px);

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
// html {
//   font-size: 16px;
// }

body {
    overflow-y: hidden !important;
}

@mixin expanding-avatar {
    min-width: 85px !important;
    width: 85px !important;
    height: 85px;
    object-fit: cover;
    margin: 10px;
    border-radius: 15px;
    div.text-white {
        font-size: 40px;
        font-weight: 500;
    }
}

@mixin compact-avatar {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px;
    border-radius: 5px;
    div.text-white {
        font-size: 25px;
        font-weight: 500;
    }
}

@mixin user-expanding-info-view {
    .user-info-avatar {
        @include expanding-avatar();
    }
    text {
        display: inline-block !important;
    }
}

@mixin user-compact-info-view {
    display: flex;
    padding: 10px 0px 0px 0px;
    margin-bottom: 0px;
    .user-info-avatar {
        @include compact-avatar();
    }
    text {
        display: none !important;
    }
}

@mixin menu-compact-view {
    min-width: $compact-menu-width;
    max-width: $compact-menu-width;
    .app-layout__sidebar-header {
        max-width: $compact-menu-width;
        min-width: $compact-menu-width;
        padding: 5px !important;
        height: $compact-menu-nav-header-height - 1px;
    }
    .app-layout__menu-list {
        max-width: $compact-menu-width;
        min-width: $compact-menu-width;
        display: block;
        .app-layout__menu-input-search {
            display: none;
        }
        .app-layout__menu-list-item {
            display: flex;
            align-items: center;
            justify-content: center;
            .app-layout__menu-list-title,
            .app-layout__menu-list-icon-arrow {
                display: none;
            }
        }
    }
    .app-layout__sidebar-user-info {
        @include user-compact-info-view();
    }
    .app-layout__sub-menu-list {
        display: none;
    }
    .app-layout__sidebar-button-toggle {
        width: $compact-menu-width;
        .app-layout__sidebar-button-toggle-text {
            display: none;
        }
    }

    .app-layout__sidebar-header-logo {
        display: none;
    }

    .app-layout__sidebar-header-logo-icon {
        display: flex;
        width: 100%;
    }
}

@mixin menu-expanding-view {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;

    .app-layout__sidebar-header {
        padding: 30px 20px;
        height: 120px;
    }

    .app-layout__sidebar-header-logo {
        display: flex;
    }

    .app-layout__sidebar-header-logo-icon {
        display: none;
    }

    .app-layout__sidebar-header {
        max-width: $expand-menu-width;
        min-width: $expand-menu-width;
        padding: 30px 20px !important;
        height: 120px;
    }
    .app-layout__menu-list {
        max-width: $expand-menu-width;
        min-width: $expand-menu-width;
        .app-layout__menu-input-search {
            display: block;
        }
        .app-layout__menu-list-item {
            display: flex;
            flex-direction: column;
            justify-content: start;
            margin-top: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            i {
                color: $light-opposite-muted-color;
            }

            :hover {
                cursor: pointer;
            }
        }

        .app-layout__menu-list-item-inner {
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
        }

        .app-layout__menu-list-item-active {
            background: rgba($color: #ffffff, $alpha: 0.3);

            i {
                color: $light-color;
            }

            .app-layout__menu-list-title {
                color: $light-color;
            }
        }

        .app-layout__sub-menu-item-active {
            .app-layout__menu-list-title {
                color: $light-color !important;
            }
        }

        .app-layout__menu-list-title {
            color: $light-opposite-muted-color;
            margin-left: 20px;
            flex: 1;
        }

        .app-layout__sub-menu-list {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .app-layout__sub-menu-item {
            padding: 10px;
            color: $light-opposite-muted-color;
            i {
                color: $dark-color;
            }
        }
        .app-layout__sub-menu-item {
            .app-layout__menu-list-title {
                display: flex !important;
                width: 100% !important;
            }
            text-align: start !important;
        }
    }
    .app-layout__sidebar-user-info {
        @include user-expanding-info-view();
    }
    .app-layout__menu-list-title,
    .app-layout__menu-list-icon-arrow {
        display: inline !important;
        text-align: start !important;
    }
}

#app-layout__content {
    min-height: 100vh;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    width: $compact-main-width;
}

.app-layout__wrapper {
    background-color: #fff !important;
    .app-layout__navbar {
        height: $compact-menu-nav-header-height;
        padding: 8px 16px 8px 4px;
        .d-badge__container-index .d-badge__badge-wrapper {
            right: -4px;
            top: 0px;
        }
    }
    #app-layout__sidebar {
        max-width: $expand-menu-width;
        min-width: $expand-menu-width;
        .app-layout__sidebar-header {
            max-width: $expand-menu-width;
            min-width: $expand-menu-width;
        }
        .app-layout__sidebar-user-info {
            .user-info-avatar {
                @include expanding-avatar();
            }
        }

        .app-layout__sub-menu-item:hover {
            background-color: rgba($color: #fff, $alpha: 0.2);
        }
        .app-layout__sidebar-header-logo-icon {
            display: none;
        }

        .app-layout__menu-list-item-inner {
            transition: 0.5s all linear;
            i {
                transition: 0.5s all linear;
            }
        }

        .app-layout__menu-list-item-inner--opened {
            .app-layout__menu-list-icon-arrow {
                transform: rotate(180deg);
            }
        }

        .app-layout__menu-list-item i,
        .app-layout__menu-list-title {
            color: #fff !important;
        }
        .app-layout__sub-menu-item-active {
            .app-layout__menu-list-title {
                font-weight: 700 !important;
            }
        }
    }

    #app-layout__content {
        height: 100% !important;
        overflow-y: scroll !important;
        background-color: transparent !important;
        width: $expand-main-width !important;
    }
    .app-layout__menu-list-item-sub-active {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.app-layout__wrapper {
    #app-layout__sidebar.active {
        position: absolute;
        left: 0;
        min-width: $compact-menu-width;
        z-index: 9999;
        height: 100%;
        @include menu-compact-view();
    }
    #app-layout__content.active {
        background-color: transparent !important;
        width: $compact-main-width !important;
        margin-left: $compact-menu-width;
    }
}

.app-layout__wrapper {
    #app-layout__sidebar:hover {
        @include menu-expanding-view();
    }
}

.app-layout__wrapper {
    .mouse-enter#app-layout__content.active {
        background-color: transparent !important;
        width: $compact-main-width !important;
    }
    .mouse-enter#app-layout__content {
        background-color: transparent !important;
        width: $expand-main-width !important;
    }
}

.app-layout__page-header {
    & > div:first-child {
        padding: 0.5rem 1.5rem;
        border-bottom: 1px solid $border-color;
        align-items: center;
        margin-bottom: 0 !important;
    }
    & > div:nth-child(2) {
        border-bottom: 1px solid $border-color;
        padding-right: 1.5rem;
        .d-input-search {
            border: 0 !important;
        }
        .d-button {
            background-color: #fff !important;
            color: $primary-color;
        }
    }
    .h4 {
        font-size: 1rem;
        color: $primary-color;
    }
    .d-button {
        height: 34px;
        font-size: 12px !important;
        i {
            margin-left: 0 !important;
        }
    }
}

@media print {
    .no-print {
        display: none;
        height: 0 !important;
    }
    div[class^="easy-print-NoPrint"] {
        height: 0;
        display: none;
    }
    body.sidebar-mini.sidebar-collapse .content-wrapper,
    .sidebar-mini.sidebar-collapse .right-side,
    .sidebar-mini.sidebar-collapse .main-footer {
        margin-left: 0px !important;
    }

    .content {
        padding: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #app-layout__sidebar,
    .app-layout__navbar,
    .fullscreen-button,
    .webphone-widget {
        display: none !important;
    }
    .main-content {
        overflow: auto !important;
        height: auto !important;
        padding: 0 !important;
    }
    .app-layout__wrapper {
        height: auto !important;
        #app-layout__content {
            height: auto !important;
            margin-left: 0 !important;
            width: 100% !important;
            &.active {
                width: 100% !important;
            }
        }
    }
    a {
        text-decoration: none !important;
    }
    .ant-table-thead {
        color: $dark-color !important;
        * {
            color: $dark-color !important;
        }
    }
    *::-webkit-scrollbar {
        display: none !important;
    }
}
