@import "../variables";

$border-color: #dee2e6;
$border-event-day-drawer-color: $warning-color;

.my-big-calendar__container {
    .rbc-header {
        border: none;
        border-bottom: 1px solid $border-color;
    }

    .rbc-month-view {
        border: none;
        border-bottom: 1px solid $border-color;
    }

    .rbc-month-row {
        border-right: 1px solid $border-color;
    }


    .rbc-row-segment:focus-visible {
        outline: none !important;
        background-color:transparent !important;
    }

    .my-big-calendar__date-cell-overlay {
        pointer-events: painted;
    }

    .rbc-off-range-bg {
        background-color: $bg-muted-color !important;
    }
    // for event
    .my-big-calendar__event-container {
        &:hover {
            z-index: 999;
            cursor: pointer;
        }
        &:focus {
            outline: none !important;
            border: none !important;
        }
    }

    .rbc-background-event {
        border-radius: 0px !important;
    }

    .rbc-event {
        border-radius: 0 !important;
        border: none;
        background-color: rgba($color: $primary-color, $alpha: 0.9) !important;
    }

    .rbc-event:hover {
        background-color: rgba($color: $primary-color, $alpha: 0.8) !important;
    }

    .rbc-event:focus-visible {
        outline: none !important;
    }

    .rbc-selected {
        background-color: $primary-color !important;
    }
}

.calendar-body_container {
    .my-big-calendar__date-cell {
        background-color: $light-color;
        &:hover {
            background-color: rgba(136, 76, 178, 0.15) !important;
            border: 1px solid #884cb2 !important;
        }
    }
    .rbc-event {
        padding: 5px 0px !important;
    }

    .calendar-event-wrapper_popover-selected {
        border-left: 5px solid $warning-color !important;
        border-top: 1px solid $warning-color !important;
        border-bottom: 1px solid $warning-color !important;
    }
}

.calendar-day-drawer_container {
    .rbc-event {
        min-height: 40px !important;
        padding: 7.5px 0px !important;
        margin: 0px 5px;
        border-left: 5px solid $border-event-day-drawer-color !important;
        border-top: 1px solid $border-event-day-drawer-color !important;
        border-bottom: 1px solid $border-event-day-drawer-color !important;
        .rbc-event-label {
            display: none;
        }
        .rbc-event-content {
            // display: flex;
            // align-items: flex-start;
            // background-color: $primary-color;
            // padding-top: 7px;
        }

        .calendar-event-wrapper_popover-selected {
            border-left: 5px solid $warning-color !important;
            border-top: 1px solid $warning-color !important;
            border-bottom: 1px solid $warning-color !important;
        }
    }

    .rbc-background-event {
        background-color: $primary-color !important;
        width: 100% !important;
        .calendar-day-drawer_event-container {
            position: relative;
            top: 0;
            left: 0;
            right: auto !important;
            bottom: auto !important;
            min-width: 200px;
            padding: 10px;
        }
    }
}

.calendar-day-drawer_event-popover-detail {
    .ant-popover-content {
        // padding-left: -200px !important;
        // transform: translateX(-100px) !important;
    }
}

.calendar-event-item_detail_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.calendar-event-wrapper_popover {
    padding-right: 0px !important;
    padding-left: 20px !important;
    .ant-popover-content {
        // transform: translateX(100%);
    }
    .ant-popover-arrow {
        left: -8px !important;
        transform: rotate(-135deg) !important;
        background-color: rgb(85, 137, 137) !important;
        border-top-color: rgb(85, 137, 137) !important;
        border-right-color: rgb(85, 137, 137) !important;
        width: 20px;
        height: 20px;
        top: 45% !important;
        z-index: 1 !important;
    }
    .ant-popover-inner-content {
        background-color: rgb(85, 137, 137) !important;
    }
    .ant-popover-inner {
        background-color: rgb(85, 137, 137) !important;
        z-index: 2 !important;
        position: relative;
    }
}
