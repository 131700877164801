$height-A5: 262mm;
$width-A5: 185mm;

.treatment-print__container {
    width: $height-A5;
    height: $width-A5;
    background-color: white;
    display: flex;
    // padding: 10px;
    //   border: 1px solid #eeeeee;
    //   transform: rotate(-90deg);
}

.treatment-material__table-row {
    td {
        padding: 10px !important;
    }
}
