.note-item__container {
    position: relative;
}
.note-item__container:hover {
    background-color: rgba(77, 126, 127, 0.06);
    .note-item__edit-delete {
        display: flex;
    }
}

.note-item__edit-delete {
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
}
