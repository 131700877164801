.media-manager__image-item-view-list {
    width: 3rem;
    height: 1.5rem;
    object-fit: cover;
}

.media-manager__button-mode-view {
    width: 2rem !important;
    height: 2rem;
    margin-left: 0.25rem;
    display: flex;
    justify-content: center;
}

.media-manager__media-item-grid {
    width: 141px;
    margin: 1rem;
}

.media-manager__image-item-view-grid {
    width: 141px;
    height: 141px;
    background-color: #f7f7f7;
    object-fit: contain;
}
.media-manager__image-title-grid {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    width: 100%;
}

.media-manager__media-detail-image {
    object-fit: contain;
    width: 100%;
    height: auto;
}

.media-manager__media-list {
    height: 65vh;
}

.media-manager__modal-select-media {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    top: 0px !important;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0px;
    > .ant-modal-content {
        width: 85vw;
        border-radius: 0px;
    }
    .ant-modal-body {
        padding: 0px;
    }
}

.media-manager__modal-select-folder {
    .ant-modal-body {
        max-height: 80vh;
        overflow: auto;
    }
}
