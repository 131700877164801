$link-color:                    #4D7E7F                                 !default;
$primary-color:                 #4D7E7F                                 !default;
$primary-color-15:              rgba(88, 124, 97, 0.15)                   !default;
$primary-color-30:              rgba(88, 124, 97, 0.3)                   !default;
$primary-color-50:              rgba(88, 124, 97, 0.5)                   !default;
$tab-bg:                        transparent                                !default;
$dark-color:                   #4D7E7F                                  !default;
$tab-inactive-bg:              white                                    !default;
$tab-border-color:             white                                    !default;


@import "../../node_modules/d-react-components/dist/variables";

 